// File: _drawer.scss

div {

  &.MuiDrawer-root {

    div.MuiPaper-root {
      background: $celeste_dubra;
      border-color: $celeste_dubra;

      ul.MuiList-root  {

        &.user_menu {
          margin-top: auto;
        }

        div.MuiButtonBase-root {

          div.MuiListItemIcon-root {
            color: $blanco;
          }
        }
      }
    }
  }
}