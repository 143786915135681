// File: _attentions.scss

.guarantee-box {

  .guarantee-box-content {

    .guarantee-box-items {

      li {
        list-style: none;
      }

      .guarantee-box-waiting {
        text-align: center;
      }
    }
  }
}