// File: _boleta.scss

/*@media print {*/
  @page {
    size: portrait;
  }

  .relativeCSS {
    padding-top: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    text-align: center;}
  
  #header_data_dubra {
    font-weight: 400;
    font-size: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
  
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;}
  
    .detail_top_boleta {
      display: block;
      font-weight: 600;
      text-transform: uppercase;}
  }
  
  #boleta_box,
  .boleta_box {
    width: 280px;
    margin: 0 auto;
  
    #logo_dubra {
      max-width: 100px;}
  
  
    .item_detail_head {
      border-bottom: solid 1px #000;
      text-align: left;
  
      .title_detail_head {
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;}
  
      .info_detail_head {
        padding-bottom: 5px;
        font-size: 12px;
        font-weight: 400;
  
        .subinfo_detail_head {
          font-weight: 300;}
      }
  
      &.separate_item {
        border: none;
  
        .title_detail_head {
          font-weight: 600;}
  
        .info_detail_head {
          padding: 0;
          font-size: 12px;
          font-weight: 400;
          text-transform: capitalize;}
      }
  
    }
  
  }
  
  .col_30 {
    width: 30%;
    float: left;}
  
  .col_50 {
    width: 48%;
    float: left;}
  
  .col_50b {
    width: 47%;
    float: left;}
  
  .suelta {
    clear: both;}
  
  .text-center {
    text-align: center;}
  
  .text-left {
    text-align: left;}
  
  .text-right {
    text-align: right;}
  
  .custom_col {
    padding-bottom: 10px;
  
    .col-2 {
      width: 50%;
      float: left;}
  
    .col-4 {
      width: 33%;
      float: left;}
  }
  
  #boleta_bottom {
    width: 280px;
    margin: 15px auto 50px auto;
    //font-size: 12px;
    font-size: 9px;}
  
  #bloque_detalles_compra {
    padding-top: 20px;
  
    h4 {
      margin: 0;
      font-size: 15px;
      text-transform: uppercase;}
  
    h5 {
      margin: 0;
      font-size: 11px;
      text-transform: uppercase;}
  
    .detalle_productos_subtotal {
      padding-top: 5px;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
  
      span {
        font-size: 11px;
        font-weight: 400;}
    }
  }
  
  
  .bloque_detalle_productos {
    padding-bottom: 15px;
  
    .detalle_producto_unidad {
      font-size: 12px;
  
      .detalle_producto_cantidad {
        width: 15%;
        float: left;
      }
  
      .detalle_producto_nombre {
        width: 60%;
        float: left;
      }
  
      .detalle_producto_total {
        width: 24%;
        float: left;
      }
    }
  
  }

  #detalle_pagos,
  .detalle_pagos {

    .item_detalle_pago {
      font-size: 12px;

      .nombre_detalle_pago {
        width: 45%;
        float: left;}

      .cantidad_detalle_pago {
        width: 30%;
        float: left;}

      .monto_detalle_pago {
        width: 24%;
        float: left;}
    }

    #titulo_pagos_detalle {
      .nombre_detalle_pago,
      .cantidad_detalle_pago {
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;}
    }
    
  }
  
  #bloque_detalle_pagos {
    padding-top: 20px;
  
    h4 {
      margin: 0;
      font-size: 15px;
      text-transform: uppercase;}
  
    h5 {
      margin: 0;
      font-size: 11px;
      text-transform: uppercase;}

  
    #titulo_pagos_detalle {
  
      .col-4 {
        width: 45%;
        float: left;}
  
      .col-6 {
        width: 24%;
        float: left;}
  
      margin: 0;
      font-size: 11px;
      text-transform: uppercase;
  
    }
  
  }
  
  
  
  
  // RETORNO
  
  #bloque_retorno {
    border-bottom: solid 1px #000;
    padding-bottom: 5px;
  
    #bloque_QR {
      width: 40%;
      float: left;
  
      img {
        max-width: 100%;}
    }
  
    #bloque_datos {
      width: 60%;
      float: left;
    }
  }

  /*
  #bloque_detalles_compra {
    border-bottom: solid 1px #000;
  }
  */
  
  // Garantia
  .bajada_detalle_garantia {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: solid 1px #000;
  }
  
  .no_garantia {
    text-decoration: line-through;}
  
  .detalle_producto_cambio,
  .detalle_credito_motivo {
    font-size: 9px;
    font-style: italic;}
    


/* } */