// File: _login.scss

#login_container {
  
  .login_avatar {
    margin: 10px;
    background-color: $celeste_dubra;
  }
  
  .login-extra-link {
    padding: 15px 0 0 0;
    text-align: center;
  }
  
}