// File: _cart.scss

div {

  .cart-header {
    text-align: center;
  }

  .item-cart-box {
    padding-top: 10px;
    border-bottom: solid 2px $celeste_dubra_claro;
    position: relative;
    overflow: hidden;

    .option-settings {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 5;
      @include transition( .4s );

      &.active {
        left: 0;
      }
      

      .option-cancel,
      .option-action {
        display: flex;
        flex-direction: column;

        .btn {
          display: flex;
          flex-grow: 1;
        }
      }

      .option-cancel {
        
      }

      .option-action {
        
      }
    }

    .item-cart-box-name {
      display: flex;
      align-items: center;

      .item-cart-box-image {
        width: 110px;
        height: 110px;
        background: $gris_sidebar;
        margin-right: 10px;
        //padding-top: 10px;
        padding: 15px;

        img {
          max-width: 100%;
        }
      }
    }

    .item-cart-box-adults,
    .item-cart-box-junior,
    .item-cart-box-days {
      padding: 0 6px;
      display: flex;
      align-items: center;
    }
    
    .item-cart-box-adults {}

    .item-cart-box-junior {}
    
    .item-cart-box-days {}
    
    .item-cart-box-action {
      display: flex;
      align-items: center;

      button {
        height: 100%;
        display: flex;
        flex-grow: 1;
        font-size: 24px;
        color: $primary_text;
        justify-content: center;
      }
    }
  }
}

.change-box {
  
  .change-box-content {
    
    .change-box-products {

      .change-box-products-item {

        .change-box-products-item-data {
          padding: 15px 0;
        }
      }
    }

  }
  
}