// File: _forms.scss


.form_container {

  .form_title {
    font-weight: 400;
    color: $primary_dubra;
    text-align: center;
  }

  .form_detail {

    .form_item {
      max-width: 500px;
      margin: 0 auto;
      padding: 0 15px 40px 15px;
      text-align: center;

      &.expanded {
        max-width: 750px;
      }

      .form_item_title {
        font-size: 24px;
        font-weight: 400;
        color: $primary_text;

        .form_item_require {
          font-weight: 700;
          color: $primary_cancel;
        }
      }

      .form_item_help {
        padding: 0 0 10px 0;
        font-size: 14px;
        font-weight: 300;
        color: $primary_text;
      }
      
      .form_item_element {
        padding: 10px 0 0 0;

        .MuiFormControl-root {
          width: 100%;

          input {
            text-align: center;
          }
        }
      }

      .form_table {

        .form_table_item {
          font-size: 17px;
          text-align: left;
          background: $secondary_table;
          margin: 0 0 5px 0;

          &.form_table_item_header {
            background: $white;
          }

          .form_table_header,
          .form_table_content {
            width: 100%;
            padding: 9px 15px 5px 15px;
            margin: 0 0 3px 0;
          }
          
          .form_table_header {
            border-bottom: solid 1px $secondary_gray;
            color: $secondary_text;
          }
  
          .form_table_content {
            color: $primary_text;
          }

          .form_col_center {
            text-align: center;
          }
        }

      }
    }
  }

  .form_actions {
    display: flex;

    .action_cancel,
    .action_go {
      padding: 10px 15px;
      border: none;
      flex: auto;
      color: $white;
    }

    .action_cancel {
      background: $primary_cancel;
      text-align: left;

      &:hover {
        background: $primary_cancel_hover;
      }
    }

    .action_go {
      background: $primary_dubra;
      text-align: right;
      
      &:hover {
        background: $primary_dubra_hover;
      }
    }
  }
}

.input-number {
  
  .input-number-less,
  .input-number-more {
    width: 25%;
    text-align: center;
  }

  .input-number-less {
    
  }

  .input-number-input {
    width: 50%;

    input {
      width: 100%;
      text-align: center;
    }
  }

  .input-number-more {}

}


.form-container-common {
  
  .form-detail-big {
    padding-top: 30px;
    max-width: 500px;

    .form-title {
      font-weight: 400;
      color: $primary_dubra;
    }
    
    .form-item {
      padding: 0 0 25px 0;
    }
  }
}


div {

  &.form-item-payment {
    padding-bottom: 20px;
  }
}