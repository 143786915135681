// File: _buttons.scss

.btn-dubra-big {
  font-size: 25px;
}

.btn-container-dashboard {
  display: flex;
  justify-content: center;

  button {
    margin: 10px;
  }
}

button {

  &.btn {
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;

    &.btn-simple,
    &.btn-outlined {
      padding: 10px 22px;
      border: none;
      font-size: 14px;
      cursor: pointer;
      @include border-radius( 3px );
      @include shadow( 1px, 1px, 3px, 1px, rgba(0, 0, 0, .2) );
    }

    &.btn-outlined {
      background: none !important;
      @include noshadow();

      &.btn-dubra {
        color: $celeste_dubra;
      }

      &.btn-dubra-cancel {
        color: $rojo;
        text-transform: uppercase;
      }
    }
  
    &.btn-right {
      justify-content: flex-end;
    }
  
    &.btn-left {
      justify-content: flex-start;
    }
  
    &.btn-dubra {
      background: $celeste_dubra;
      color: $white;

      &:hover {
        background: $celeste_dubra_oscuro;
      }
    }
    
    &.btn-dubra-cancel {
      background: $rojo;
      color: $white;
    }

    &.btn-dubra-info {
      background: $amarillo;
      color: #333;
    }
    
    
    &.btn-dubra-success {
      background: $verde_oscuro;
      color: $white;
    }
  
    &:disabled,
    &[disabled] {
      background: $gris_action_bar_03 !important;
    }
    
  
  }

}

.btn-clean {
  background: transparent;
  border: none;
}

.modal_actions {

  button {
    padding: 10px 20px;
    border: none;
    background-color: $celeste_dubra;
    color: #fff;
    font-size: 22px;
    cursor: pointer;

    &:hover {
      background: $celeste_dubra_oscuro;
    }

    &.normal {}

    &.cancel {
      background: $rojo;

      &:hover {
        background: $rojo_oscuro;
      }
    }

    &.disabled {
      @include opacity( .6 );
    }
  }
}