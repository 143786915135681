// File: _modal.scss

.modal {
  
  .modal_block {

    .modal_content {
      padding: 0 20px 50px 20px;
    }

    .modal_actions {
      display: flex;

      button {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;

        //&.buttontext_left {}

        &.buttontext_right {
          justify-content: flex-end;
        }

      }
    }
  }
}