// File: _header.scss

header {

	&.MuiPaper-root {
		background: $blanco;

		div {

			&.MuiToolbar-root {
				justify-content: space-between;
				//align-items: flex-start;

				.header_content {
					color: $gris_texto_claro;

					&.header_logo {
						display: flex;
						
						img {
							margin-left: 10px;
						}
					}

					&.header_date {
						//align-self: center;
					}

					&.header_info {
						//align-self: center;
						display: flex;
						flex-direction: column;
						align-items: flex-end;

						.weather_info_box {
							display: flex;
							align-items: center;

							svg {
								margin-right: 7px;
							}
						}
					}

				}

			}

			&.MuiTabs-root {
				color: $gris_texto_claro;

				.MuiTab-wrapper {
					text-transform: capitalize;
					font-weight: 500;
				}
			}
		}

		.weather_icon_container {
			svg {
				fill: $celeste_dubra;	
			}
		}
			
	}
}