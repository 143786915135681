// File: _paper.scss

div {

  &.MuiPaper-root {

    &.content {
      margin: 0 0 15px 0;
    }

    .paper_content_container {
      padding: 15px 20px;

      .paper_content_container_data {
        padding-top: 10px;
      }
    }

  }
}