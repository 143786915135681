// File: _variables.scss

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Colours
$gris_oscuro: #edeff5;
$gris_texto_oscuro: #6b6b6b;
$gris_texto_claro: #9c9c9c;
$gris_lineas_hr: #dcdcdc;
$gris_menu_claro: #dadce3;
$gris_tabla_claro: #f6f7fa;
$gris_sidebar: #f6f7fa;
$gris_sidebar_detail: #b0b1b2;
$gris_subtitle_dashboard: #bcbcbc;

$gris_action_bar_00: #959595;
$gris_action_bar_01: #9e9e9e;
$gris_action_bar_02: #a8a8a9;
$gris_action_bar_03: #c3c3c3;

$celeste_dubra: #00a3da;
$celeste_dubra_oscuro: #008ab9;
$celeste_dubra_claro: #abddef;
$celeste_dubra_claro_ultra: #ebfaff;
$blanco: #fff;
$white: #fff;


$primary_dubra: #00a3da;
$primary_text: #6b6b6b;
$primary_cancel: #ff677a;


$primary_dubra_hover: #008ab9;
$primary_cancel_hover: #dc5262;

$secondary_text: #9c9c9c;
$secondary_gray: #dcdcdc;
$secondary_table: #f6f7fa;


$rojo: #ff677a;
$rojo_oscuro: #dc5262;

$verde_claro: #a1e7b0;
$verde_oscuro: #4cbc87;

$amarillo: #f5a623;
$amarillo_oscuro: #f6aa00;

// Interpretaciones de Colores
$menu_a: $blanco;

// Body Elements
$bg: $gris_oscuro;
$blanco_dubra: $blanco;
$blanco_bg: $blanco;

// Extra elements
$raleway_font: 'Raleway', sans-serif;

// Sizes
$sidebar_width: 90px;
$header_home_height: 60px;


$h1: 30;

$padding_menu_item: 40px;





// Breakpoints
$sm_md: 991px;