// File: _content.scss

.all_container {

  .container_all {
    flex-grow: 1;
  
    &.logged_user {
      padding: 64px 0 0 0;
    }
  
    .content_all {
      min-height: 100%;
      padding: 20px 15px;
      display: flex;
  
      .content {
        flex-grow: 1;
      }
  
      .content_mini {
        max-width: 1140px;
        margin: 0 auto;
      }

      .content_detail {
        padding-top: 15px;
      }
  
    }
  }

  &.active-tabs {

    .container_all {
      padding: 112px 0 0 0;
    }
  }

}

.hidden_content_container {
  flex-grow: 1;

  .active_content {
    width: 100%;
    height: 100%;
    @include transition( .6s );
    overflow: hidden;

    &.disabled {
      height: 0;
    }
  }
  
  .hidden_content {
    width: 100%;
    height: 0;
    @include transition( .6s );
    overflow: hidden;

    &.active {
      height: auto;
      overflow: auto;
    }
  }

  //&.top_animation {}
  //&.left_animation {}
  //&.right_animation {}
  &.bottom_animation {
    
    .active_content {}

    .hidden_content {}
  }
}

.summary-filters {
  
  .MuiFormControl-root {
    width: 100%;
  }
}

.icon-green {
  color: $verde_claro;
}