// File: _buttons.scss

.error-box {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 0 0 10px 0;
  }
}