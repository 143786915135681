// File: _base.scss

body, html {
	height: 100%;
	//font-family: Arial, Helvetica, sans-serif;

	#root {
		height: 100%;

		.all_container {
			min-height: 100%;
			display: flex;
		}
	}
}

.empty-value {
	text-align: center;
}