// File: _cash_register.scss

.cash-register-box {
  display: flex;
  flex-grow: 1;

  .MuiPaper-root {

    &.fullwidth {
      min-height: 100%;
      flex: auto;
      display: flex;
      flex-direction: column;
    }

    &.cash-register-sale {
      display: flex;
    }
  }

  .simple-box-container {
    //height: 90vh;
    padding-top: 120px;
    font-size: 24px;
    font-weight: 300;
    color: $gris_texto_oscuro;
    text-align: center;
  }

  .cash-register-container {
    height: 100%;
    
    .cash-register-content {
      padding: 20px 15px;
      display: flex;
      flex-direction: column;

      .cart-zone {
        padding: 10px 15px;
        border: dashed 2px $gris_texto_claro;
        /*display: flex;
        flex-direction: column;*/
        flex-grow: 1;

        &.cart-empty,
        &.cart-drop,
        &.cart-active {
          justify-content: center;
          align-items: center;
        }

        &.cart-drop {
          border-color: $celeste_dubra;
        }
        
        &.cart-active {
          background: $celeste_dubra_claro_ultra;
          border-color: $celeste_dubra;
        }

        &.cart-empty {} 

        
        
      }
    }

    .cash-register-sidebar {
      background: $gris_tabla_claro;
      display: flex;
      flex-direction: column;

      .cash-register-sidebar-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        
        &.info-data {
          padding: 20px 15px;
        }

        .cash-register-sidebar-item {
          padding: 0 0 20px 0;

          .cash-register-sidebar-item-title {
            font-size: 16px;
            font-weight: 500;
            color: $gris_texto_oscuro;
            text-transform: uppercase;
          }

          .cash-register-sidebar-item-detail {

          }
        }

        .cash-register-sidebar-step {
          display: flex;
          flex-direction: column;

          .btn-sidebar-action {
            justify-content: flex-end;
          }

          .cash-register-sidebar-step-content {
            //max-height: 500px;
            height: 0;
            padding: 0 15px;
            overflow: hidden;

            .productItemMini {
              margin-bottom: 10px;
              padding: 0 5px;

              .productDetail {
                height: 100%;
                text-align: center;
              }
            }
          }

          &.active {
            flex-grow: 1;

            .btn-sidebar-action {
              background: $celeste_dubra;
              color: $white;
              justify-content: space-between;
            }

            .cash-register-sidebar-step-content {
              padding-top: 15px;
              padding-bottom: 15px;
              flex-grow: 1;
              overflow-x: hidden;
              overflow-y: auto;
              @media screen and (max-width: $sm_md){
                height: auto;
              }
            }
          }

          &.visited {

            .btn-sidebar-action {
              justify-content: space-between;
            }

            .cash-register-sidebar-step-content {}
          }
        }
        
      }
      

      .cash-register-sidebar-actions {
        margin-top: auto;

        .btn-sidebar-action {
          justify-content: flex-end;
        }
      }
    }
  }

}

.sidebar-item-box {
  padding: 0 0 5px 0;

  .sidebar-item-title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .sidebar-item-detail,
  .sidebar-itemSac-detail {
    background: $white;
    padding: 5px 15px 5px 15px;

    &.sidebar-item-box-error {
      border: solid 1px #f44336;
    }

    &.item-form {
      margin-top: 5px;
      padding: 15px 20px;
    }

    ul.sidebar-item-detail-item {
      margin: 0;
      padding: 0;

      li {
        padding-bottom: 10px;
        list-style: none;

        h3 {
          display: flex;
          align-content: center;
        }
        
      }
    }

  }

  .sidebar-itemSac-detail {
    padding: 5px 15px;
  }
}

.btn-sidebar-action {
  width: 100%;
  background: $gris_subtitle_dashboard;
  padding: 20px 20px;
  font-size: 24px;
  font-weight: 400;
  color: $gris_texto_oscuro;
  cursor: pointer;
  border: none;
  display: flex;

  svg {
    margin-left: 15px;
  }

  &.btn-dubra {
    background: $celeste_dubra;
    color: $white;

    &:hover {
      background: $celeste_dubra_oscuro;
    }
  }

  &.step_a {
    background: $gris_action_bar_03;
    color: $white;
  }

  &.step_b {
    background: $gris_action_bar_02;
    color: $white;
  }

  &.step_c {
    background: $gris_action_bar_01;
    color: $white;
  }

  &.step_d {
    background: $gris_action_bar_00;
    color: $white;
  }

  &.cancel {
    background: $rojo;
    color: $white;
  }
}


div {
  &.MuiAvatar-root {
    &.status_active {
      background: $verde_oscuro;
    }

    &.status_closed {
      background: $primary_cancel;
    }

    &.status_pending {
      background: $amarillo;
    }
  }
}

.modal_product_box {

  .modal_productbox {

    .modal_productbox_left {
      .modal_productbox_image {
        padding: 20px;
        
        img {
          max-width: 200px;
        }
      }
  
      .modal_productbox_name {
        background: #dadce3;
        border-top: solid 2px #6b6b6b;
        color: #6b6b6b;
        font-size: 1.25rem;
        text-align: center;
        padding: 10px 20px;
      }
    }

    .modal_productbox_right {
      padding-top: 30px;
      
      .modal_productbox_size {
        padding-bottom: 30px;
      }

      .modal_productbox_input {
        width: 300px;
        padding: 10px 0 0 0;
      }
    }
  
  }

  .modal_productbox_total {
    padding: 10px 20px;
    text-align: right;
  }

}